/* Styles for Ant Design DateRangePicker - some of the properties listed in the docs below didn't work */
/* https://ant.design/components/date-picker#design-token */
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before {
    background: #C5DFFB;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before {
    background: #C5DFFB;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background: #4CAFE3;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background: #4CAFE3;
}
